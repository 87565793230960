import type { Currency } from '@aerapass/country-data';
import { currencies } from '@aerapass/country-data';
import { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import type { TpFiatCurrency } from './types';

export const fiatCurrencies = Object.values(FiatCurrencyCode).reduce(
  (acc, code) => {
    const fiatCurrency = currencies[code] as Currency | undefined;

    if (!fiatCurrency) {
      return acc;
    }

    // safe to cast as we know the code is a FiatCurrencyCode
    acc[fiatCurrency.code as FiatCurrencyCode] = {
      code: fiatCurrency.code as FiatCurrencyCode,
      decimals: fiatCurrency.decimals,
      label: fiatCurrency.name,
      symbol: fiatCurrency.symbol,
    };
    return acc;
  },
  {} as Record<FiatCurrencyCode, TpFiatCurrency>,
);
