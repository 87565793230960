import { alpha } from '@mui/material/styles';
import type { PaletteOptions } from '@mui/material/styles/createPalette';
import { brandColors } from './brandColors';
import { flattenValues, getValueFromPath, Tokens } from './designTokens';

const { Greyscale } = Tokens.BrandColours;

export const paletteLight: PaletteOptions = {
  action: {
    disabled: getValueFromPath(Tokens.ColourUsage.Disabled.Text.value),
    disabledBackground: getValueFromPath(Tokens.ColourUsage.Disabled.background.value),
  },
  background: {
    backdrop: alpha(Greyscale[900].value, 0.3),
    default: Greyscale.White.value,
    paper: Greyscale.White.value,
  },
  brand: {
    dark: getValueFromPath(Tokens.ColourUsage.Brand[400].value),
    light: getValueFromPath(Tokens.ColourUsage.Brand[200].value),
    main: getValueFromPath(Tokens.ColourUsage.Brand[300].value),
  },
  common: {
    black: Greyscale[900].value,
    white: Greyscale.White.value,
  },
  ctaCards: {
    blue: brandColors.ctaCards.blue,
    brown: brandColors.ctaCards.brown,
    pink: brandColors.ctaCards.pink,
    white: {
      contrastText: getValueFromPath(Tokens.ColourUsage.Text.White.value),
      dark: getValueFromPath(Tokens.ColourUsage.Primary.Main.value),
    },
  },
  divider: Greyscale[200].value,
  error: {
    contrastText: Greyscale[800].value,
    dark: getValueFromPath(Tokens.ColourUsage.Error.Dark.value),
    light: getValueFromPath(Tokens.ColourUsage.Error.Light.value),
    main: getValueFromPath(Tokens.ColourUsage.Error.Main.value),
  },
  ghost: {
    contrastText: Greyscale[600].value,
    dark: getValueFromPath(Tokens.ColourUsage.Button.Ghost.Hover.value), // dark is being used as the hover color
    focus: getValueFromPath(Tokens.ColourUsage.Button.Ghost.FocusStroke.value),
    hover: getValueFromPath(Tokens.ColourUsage.Button.Ghost.Hover.value),
    main: getValueFromPath(Tokens.ColourUsage.Button.Ghost.Default.value),
  },
  gradients: brandColors.gradients,
  grayscale: {
    ...flattenValues(Greyscale),
    black00: alpha(Greyscale[900].value, 0),
    black30: alpha(Greyscale[900].value, 0.3),
    black40: alpha(Greyscale[900].value, 0.4),
    black80: alpha(Greyscale[900].value, 0.8),
    offWhite: Greyscale.OffWhite.value,
    white: Greyscale.White.value,
    white00: alpha(Greyscale.White.value, 0),
    white40: alpha(Greyscale.White.value, 0.4),
    white60: alpha(Greyscale.White.value, 0.6),
    white80: alpha(Greyscale.White.value, 0.8),
  },
  info: {
    dark: getValueFromPath(Tokens.ColourUsage.Info.Dark.value),
    light: getValueFromPath(Tokens.ColourUsage.Info.Light.value),
    main: getValueFromPath(Tokens.ColourUsage.Info.Main.value),
  },
  input: {
    background: Greyscale[100].value,
    borderActive: Greyscale[400].value,
    borderNormal: Greyscale[100].value,
  },
  mode: 'light',
  primary: {
    contrastText: Greyscale.OffWhite.value,
    dark: getValueFromPath(Tokens.ColourUsage.Primary.Dark.value),
    main: getValueFromPath(Tokens.ColourUsage.Primary.Main.value),
  },
  primaryBrand: {
    contrastText: Greyscale.OffWhite.value,
    dark: getValueFromPath(Tokens.ColourUsage.Button.PrimaryBrand.Hover.value), // dark is being used as the hover color
    focus: getValueFromPath(Tokens.ColourUsage.Button.PrimaryBrand.FocusStroke.value),
    hover: getValueFromPath(Tokens.ColourUsage.Button.PrimaryBrand.Hover.value),
    main: getValueFromPath(Tokens.ColourUsage.Button.PrimaryBrand.Default.value),
  },
  primaryDark: {
    contrastText: Greyscale.OffWhite.value,
    dark: getValueFromPath(Tokens.ColourUsage.Button.PrimaryDark.Hover.value), // dark is being used as the hover color
    focus: getValueFromPath(Tokens.ColourUsage.Button.PrimaryDark.FocusStroke.value),
    hover: getValueFromPath(Tokens.ColourUsage.Button.PrimaryDark.Hover.value),
    main: getValueFromPath(Tokens.ColourUsage.Button.PrimaryDark.Default.value),
  },
  primaryLight: {
    contrastText: Greyscale[800].value,
    dark: Greyscale[100].value,
    main: Greyscale.White.value,
  },
  secondary: {
    dark: getValueFromPath(Tokens.ColourUsage.Secondary.Dark.value),
    main: getValueFromPath(Tokens.ColourUsage.Secondary.Main.value),
  },
  success: {
    dark: getValueFromPath(Tokens.ColourUsage.Success.Dark.value),
    light: getValueFromPath(Tokens.ColourUsage.Success.Light.value),
    main: getValueFromPath(Tokens.ColourUsage.Success.Main.value),
  },
  system: {
    blue: {
      dark: Tokens.BrandColours.Colours.Blue[300].value,
      light: Tokens.BrandColours.Colours.Blue[100].value,
      main: Tokens.BrandColours.Colours.Blue[200].value,
    },
    green: {
      dark: Tokens.BrandColours.Colours.Green[300].value,
      light: Tokens.BrandColours.Colours.Green[100].value,
      main: Tokens.BrandColours.Colours.Green[200].value,
    },
    orange: {
      dark: Tokens.BrandColours.Colours.Orange[400].value,
      light: Tokens.BrandColours.Colours.Orange[200].value,
      main: Tokens.BrandColours.Colours.Orange[300].value,
    },
    red: {
      dark: Tokens.BrandColours.Colours.Red[300].value,
      light: Tokens.BrandColours.Colours.Red[100].value,
      main: Tokens.BrandColours.Colours.Red[200].value,
    },
    yellow: {
      dark: Tokens.BrandColours.Colours.Yellow[300].value,
      light: Tokens.BrandColours.Colours.Yellow[100].value,
      main: Tokens.BrandColours.Colours.Yellow[200].value,
    },
  },
  text: {
    dark: getValueFromPath(Tokens.ColourUsage.Text.Dark.value),
    light: getValueFromPath(Tokens.ColourUsage.Text.Light.value),
    link: getValueFromPath(Tokens.ColourUsage.Text.Link.value),
    // primary & secondary are default mui theme properties and used all over the mui components unless overridden
    primary: getValueFromPath(Tokens.ColourUsage.Text.Dark.value),
    secondary: getValueFromPath(Tokens.ColourUsage.Secondary.Dark.value),
    white: getValueFromPath(Tokens.ColourUsage.Text.White.value),
  },
  warning: {
    dark: getValueFromPath(Tokens.ColourUsage.Warning.Dark.value),
    light: getValueFromPath(Tokens.ColourUsage.Warning.Light.value),
    main: getValueFromPath(Tokens.ColourUsage.Warning.Main.value),
  },
};
